<template>
  <div class="list-two">
    <!-- 查询条件 -->
    <div class="filter-container">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="客户姓名">
          <el-input
            clearable
            v-model="formInline.customerName"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户电话">
          <el-input
            clearable
            v-model="formInline.customerTel"
            placeholder="请输入客户电话"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="活动区域">
          <el-select v-model="formInline.region" placeholder="活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>  -->

        <!-- 时间范围 -->
        <el-form-item label="客户创建时间">
          <el-date-picker
            type="daterange"
            v-model="dateRange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            unlink-panels
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
           <!-- 定义进度条组件 -->
          <el-progress v-if="showProgress" type="circle" :percentage="insertProgress"></el-progress>
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="danger" @click="insertDataToDatabase">生成到数据库</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" style="max-height: 450px; overflow-y: auto; width: 100%" height="450" border size="small" stripe tooltip-effect="dark">
      <el-table-column prop="serviceNo" label="服务号编号" width="140" fixed>
      </el-table-column>
      <el-table-column prop="activeScore" label="活跃度分值" width="120">
      </el-table-column>
      <el-table-column prop="salesman" label="导购" width="120">
      </el-table-column>
      <!--  单独设置样式 -->
      <el-table-column prop="customerName" label="客户姓名" width="140">
        <template v-slot="scope">
          <span style="color: #f2694a">{{ scope.row.customerName }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="customerTel" label="客户电话" width="140"></el-table-column>
      <el-table-column  prop="customerRegionText" label="客户地址行政区划" width="120">
      </el-table-column>
      <el-table-column prop="customerAddress" width="1400" label="客户详细地址">
      </el-table-column>
      <el-table-column prop="customerSourceText" label="客户来源" width="120">
      </el-table-column>
      <!-- <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="handleDelete(scope.row)" size="mini" type="primary">客户名称</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <!-- 分页组件 -->
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>


  <script>
// 可以忽略eslint检查未使用变量错误
// eslint-disable-next-line no-unused-vars
// ... 这里是你的代码 ...
</script>
    
<script>
// 欧派默认查询时间是当前日的 2022-09-19 00:00:00 到2023-09-19 23:59:59  累计一年时间段

import { deleteTask, getCustomerList, getCustomerList2 } from "../../api/user";

export default {
  data() {
    return {
      formInline: {
        customerName: "",
        customerTel: "",
        beginCreateDt: "",
        endCreateDt: "",
      },
      dateRange: [], // 用于存储日期范围的值
      loading: true, // 初始化为 true，显示 loading 等待框
      tableData: [],
      insertProgress: 0,// 插入进度值
      showProgress: false,

      // dialogVisible: false,
      row: {}, // 传进来的行数据
      total: 0, // 总记录数
      pageNo: 1, // 当前页码
      pageSize: 20, // 每页显示的记录
    };
  },

  methods: {
    // 客户列表
    getList() {
      const params = {
        customerName: this.formInline.customerName,
        customerTel: this.formInline.customerTel,
        beginCreateDt: this.formInline.beginCreateDt,
        endCreateDt: this.formInline.endCreateDt,
        scope: "all",
        status: "DEFAULT",
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        total: 0,
        platformType: "MTDS",
      };

      if (this.dateRange && this.dateRange.length === 2) {
        params.beginCreateDt = this.dateRange[0];
        params.endCreateDt = this.dateRange[1];
      }

      console.log(params, "传参");
      
      getCustomerList(params)
        .then((res) => {
          if(res.data.data.code==110001){
            // this.$message.error(res.data.data.message);
            this.$message.error("第三方认证未通过 请检查后重试!")
            return false
          }else{
            let data = res.data.data.data.records;
            // 处理时间戳，转换为日期格式
            data.forEach((record) => {
              record.createDate = this.$options.filters.formatDate(
                record.createDate
              );
              record.updateDate = this.$options.filters.formatDate(
                record.updateDate
              );
            });
            this.tableData = data;
            // 总页数
            this.total = res.data.data.data.total; 

          }
          
        })
        .catch((err) => {
          console.error(err);
          console.log(err,"error submit!! 报错");
          this.loading = false;
        });
    },

    // 生成到数据库
      insertDataToDatabase(){
        this.$confirm("确定将当前查询到所有数据插入到数据库中吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            console.log(this.tableData.length,"data");
            if(this.tableData.length==0){
                this.$message.error("当前列表无数据,请查询到数据后执行插入操作");
                return false            
            }
            this.showProgress = true; // 显示进度条3
            console.log("执行生成数据库插入");
            const params = {
              customerName: this.formInline.customerName,
              customerTel: this.formInline.customerTel,
              beginCreateDt: this.formInline.beginCreateDt,
              endCreateDt: this.formInline.endCreateDt,
              scope: "all",
              status: "DEFAULT",
              pageSize: this.pageSize,
              pageNo: this.pageNo,
              total: 0,
              platformType: "MTDS",
            };

            if (this.dateRange && this.dateRange.length === 2) {
              params.beginCreateDt = this.dateRange[0];
              params.endCreateDt = this.dateRange[1];
            }

          getCustomerList2(params)
            .then((res) => {
              console.log(res.data.progress);
              const interval = setInterval(() => { // 启动定时器
              if (this.insertProgress >= 100) {

                clearInterval(interval); // 达到最终值，停止定时器
                this.$message.success(res.data.message);
                this.showProgress = false; // 隐藏进度条
                this.insertProgress = 0;
                return;
              }
              if (this.insertProgress < 30) {
                this.insertProgress = 30; // 第1秒到达30%的进度
              } else if (this.insertProgress < 60) {
                this.insertProgress = 60; // 第2秒到达60%的进度
              } else if (this.insertProgress < 90) {
                this.insertProgress = 90; // 第3秒到达90%的进度
              } else {
                this.insertProgress = 100; // 第4秒到达100%的进度
              }
            }, 1000); // 每隔1秒更新一次进度条

              // this.progress = res.data.progress;
              //  if (res.data.progress == 100) {
              //   this.insertProgress = 100; // 设置进度条为100
              //   this.$message.success(res.data.message);
              // }

            })
            .catch((err) => {
              console.error(err,"error");
              // console.log("error submit!! 报错");
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },

    handleSizeChange(size) {
      this.pageSize = size;
      console.log(`每页 ${size} 条`);
      this.getList(); // 根据当前设置重新获取数据
    },
    // 切换页数
    handleCurrentChange(page) {
      this.pageNo = page;
      console.log(`当前页: ${page}`);
      this.getList();
    },

    //删除任务
    handleDelete(row) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTask(row.id)
            .then((res) => {
              // eslint-disable-line no-unused-vars
              this.loading = true;
              this.$message.success("删除成功");
              this.getList();
              this.loading = false;
            })
            .catch((err) => {
              // eslint-disable-line no-unused-vars

              console.log("error submit!! 报错");
              this.loading = false;
              return false;
            });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
  },
  created() {
    // 列表
    this.getList();
  },
};

</script>
    
  
  <style>
.el-table thead {
  color: #1f2225;
}
</style>
    
  <style lang="scss" scoped>
.el-main {
  padding: 0 !important;
}
</style>